import logo from "./Logo.webp";
import profile from "./profile.webp";
import faq from "./faq.webp";
import correct_bronze from "./correct_bronze.svg";
import correct_gold from "./correct_gold.svg";
import correct_silver from "./correct_silver.svg";
import correct_platinum from "./correct_platinum.svg";
import cardlogo from "./cardlogo.svg";
import slideImage1 from "./hero/slideImage1.webp";
import LandingPageHeroImage from "./hero/banner2.webp";
import insta from "./social/insta.svg";
import venderprofile from "./venderhop/profile.webp";
import store from "./venderhop/store.svg";
import verified from "./venderhop/verified.svg";
import storeimage from "./venderhop/cardImage.webp";
import customerlogin from "./login/customer_signUp.webp";
import customersignup from "./login/customer_login.webp";
import venderlogin from "./login/venderlogin.webp";
import vendersignup from "./login/vender_signup3.webp";
import TikTok from "./tiktok.webp";

export {
  vendersignup,
  venderlogin,
  profile,
  cardlogo,
  correct_silver,
  correct_gold,
  correct_bronze,
  correct_platinum,
  verified,
  storeimage,
  store,
  venderprofile,
  logo,
  faq,
  customersignup,
  customerlogin,
  slideImage1,
  LandingPageHeroImage,
  TikTok,
  insta,
};
